import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 1,
  style: {"height":"789px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_Protocol = _resolveComponent("Protocol")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_descriptions, {
          title: "协议配置",
          bordered: ""
        }),
        _createVNode(_component_a_tabs, {
          type: "card",
          onChange: _ctx.setActive
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: item.id,
                tab: item.name
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_spin, { spinning: _ctx.loading }, {
                    default: _withCtx(() => [
                      (!_ctx.loading && _ctx.activeKey === item.id)
                        ? (_openBlock(), _createBlock(_component_Protocol, {
                            "protocol-type": _ctx.activeKey,
                            key: _ctx.activeKey
                          }, null, 8, ["protocol-type"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2))
                    ]),
                    _: 2
                  }, 1032, ["spinning"])
                ]),
                _: 2
              }, 1032, ["tab"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onChange"])
      ]),
      _: 1
    })
  ]))
}